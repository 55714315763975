export const theme = {
  landing: {
    colors: {
      primary: '#263287',
      secondary: '#011553',
      third: '#06B192',
      fourth: '#FF4952',
      fifth: '#283288',
      lightBlue: '#84a9ff',
      yellow: '#fddf78',
      textNegative: '#F4F4F4',
      neutral: '#747474',
      negativeLighter: '#f6787f',
      negative: '#f54b53'
    }
  },
  global: {
    colors: {
      white: '#fff',
      brand: '#06B192',
      brandLight: '#e8ef97',
      brandDark: '#99a50c',
      secondary: '#8a784e',
      secondaryLight: '#524c2d',
      background: '#EDEDED',
      backgroundNeutral: '#fff',
      textColor: '#444444',
      positive: '#699e56',
      negative: '#d63f3f',
      neutral: '#747474',
      headingColor: '#fff',
      third: '#FF9E1B',
      focus: '#747474'
    },
    font: {
      family: 'sans-serif',
      size: '14px',
      height: '20px'
    }
  }
};
import { SessionManager } from "./session-manager";

type UtmParam = 'utm_source' | 'utm_medium' | 'utm_campaign' | 'utm_id';

export const saveQueryParams = (currentUrl: string): void => {
  const urlParams = new URLSearchParams(currentUrl);
  
  const utmMapping: Record<UtmParam, {
    setter: (value: string) => void,
    getter: () => string
  }> = {
    'utm_source': {
      setter: SessionManager.setUtmSource,
      getter: SessionManager.getUtmSource
    },
    'utm_medium': {
      setter: SessionManager.setUtmMedium,
      getter: SessionManager.getUtmMedium
    },
    'utm_campaign': {
      setter: SessionManager.setUtmCampaign,
      getter: SessionManager.getUtmCampaign
    },
    'utm_id': {
      setter: SessionManager.setUtmId,
      getter: SessionManager.getUtmId
    }
  };

  Object.entries(utmMapping).forEach(([param, { setter, getter }]) => {
    const value = urlParams.get(param) ?? getter();
    setter(value);
  });
};
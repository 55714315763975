// sessionUtils.ts
export class SessionManager {
  private static SESSION_START_KEY = "sessionStart";
  private static USER_ID_KEY = "userId";
  private static PAGE_VISIT_COUNT_KEY = "pageVisitCount";
  private static IS_MOBILE_KEY = "isMobile";
  private static IS_COMPUTER_KEY = "isComputer";
  private static TOTAL_SESSIONS_KEY = "TOTAL_SESSIONS_KEY";
  private static BOUNCE_SESSIONS_KEY = "BOUNCE_SESSIONS_KEY";
  private static NEW_SESSION_FLAG_KEY = "isNewSession";
  private static NEW_SESSION_COUNT_KEY = "NEW_SESSION_COUNT_KEY";
  private static USER_FLOW_KEY = "userFlow";
  private static SEARCH_COUNT_KEY = "SEARCH_COUNT_KEY";
  private static RETURNING_USER_KEY = "RETURNING_USER_KEY";
  private static VISIT_FREQUENCY_KEY = "VISIT_FREQUENCY_KEY";
  private static BUTTON_ID_KEY = "buttonId";
  private static UTM_SOURCE_KEY = 'utm-source'
  private static UTM_MEDIUM_KEY = 'utm-medium'
  private static UTM_CAMPAIGN_KEY = 'utm-campaign'
  private static UTM_ID_KEY = 'utm-id'

  public static startSession(): void {
    const now = new Date().toISOString();
    sessionStorage.setItem(SessionManager.SESSION_START_KEY, now);
    SessionManager.setUserId();
    const isNewSession = localStorage.getItem(SessionManager.NEW_SESSION_FLAG_KEY) === null;
    if (isNewSession) {
      localStorage.setItem(SessionManager.NEW_SESSION_FLAG_KEY, 'false');
      SessionManager.incrementNewSessionCount();
    }
    if (!SessionManager.isReturningUser()) {
      SessionManager.setReturningUser();
    }
    SessionManager.incrementVisitFrequency();
    SessionManager.incrementTotalSessions();
    SessionManager.setDeviceFlags();
    SessionManager.incrementPageVisitCount();
  }

  public static endSession(): number | null {
    const sessionStart = sessionStorage.getItem(SessionManager.SESSION_START_KEY);
    if (sessionStart) {
      const sessionDuration = new Date().getTime() - new Date(sessionStart).getTime();
      sessionStorage.removeItem(SessionManager.SESSION_START_KEY);
      return sessionDuration;
    }
    return null;
  }

  public static getSessionDuration(): number | null {
    const sessionStart = sessionStorage.getItem(SessionManager.SESSION_START_KEY);
    if (sessionStart) {
      return new Date().getTime() - new Date(sessionStart).getTime();
    }
    return null;
  }

  private static createUUID(): string {
    let dt = new Date().getTime();
    return "xxxxxxxx-xxxx-4xxx-yxxx-xxxxxxxxxxxx".replace(
      /[xy]/g,
      function (c) {
        var r = (dt + Math.random() * 16) % 16 | 0;
        dt = Math.floor(dt / 16);
        return (c === "x" ? r : (r & 0x3) | 0x8).toString(16);
      }
    );
  }

  public static setUserId(): void {
    const existingUserId = sessionStorage.getItem(SessionManager.USER_ID_KEY);
    if (!existingUserId) {
      const userId = SessionManager.createUUID();
      sessionStorage.setItem(SessionManager.USER_ID_KEY, userId);
    }
  }

  public static getUserId(): string | null {
    const userId = sessionStorage.getItem(SessionManager.USER_ID_KEY);
    return userId;
  }

  public static incrementPageVisitCount(): void {
    let pageVisitCount = sessionStorage.getItem(SessionManager.PAGE_VISIT_COUNT_KEY);
    if (!pageVisitCount) {
      pageVisitCount = '0';
    }
    const newPageVisitCount = String(Number(pageVisitCount) + 1);
    sessionStorage.setItem(SessionManager.PAGE_VISIT_COUNT_KEY, newPageVisitCount);
  }

  public static getPageVisitCount(): number {
    const pageVisitCount = sessionStorage.getItem(SessionManager.PAGE_VISIT_COUNT_KEY);
    return pageVisitCount ? Number(pageVisitCount) : 0;
  }

  public static isMobile(): boolean {
    const isMobile = sessionStorage.getItem(SessionManager.IS_MOBILE_KEY);
    return isMobile === 'true';
  }

  public static isComputer(): boolean {
    const isComputer = sessionStorage.getItem(SessionManager.IS_COMPUTER_KEY);
    return isComputer === 'true';
  }

  private static setDeviceFlags(): void {
    const userAgent = navigator.userAgent.toLowerCase();
    const isMobile = /android|webos|iphone|ipad|ipod|blackberry|iemobile|opera mini/i.test(
      userAgent
    );
    const isComputer = !isMobile;
    sessionStorage.setItem(SessionManager.IS_MOBILE_KEY, isMobile ? 'true' : 'false');
    sessionStorage.setItem(SessionManager.IS_COMPUTER_KEY, isComputer ? 'true' : 'false');
  }

  private static incrementTotalSessions(): void {
    let totalSessions = sessionStorage.getItem(SessionManager.TOTAL_SESSIONS_KEY);
    if (!totalSessions) {
      totalSessions = '0';
    }
    const newTotalSessions = String(Number(totalSessions) + 1);
    sessionStorage.setItem(SessionManager.TOTAL_SESSIONS_KEY, newTotalSessions);
  }

  public static getBounceRate(): number {
    const totalSessions = sessionStorage.getItem(SessionManager.TOTAL_SESSIONS_KEY);
    const bounceSessions = sessionStorage.getItem(SessionManager.BOUNCE_SESSIONS_KEY);
    if (totalSessions && bounceSessions) {
      const total = Number(totalSessions);
      const bounce = Number(bounceSessions);
      return (bounce / total) * 100;
    }
    return 0;
  }

  private static incrementBounceSessions(): void {
    let bounceSessions = sessionStorage.getItem(SessionManager.BOUNCE_SESSIONS_KEY);
    if (!bounceSessions) {
      bounceSessions = '0';
    }
    const newBounceSessions = String(Number(bounceSessions) + 1);
    sessionStorage.setItem(SessionManager.BOUNCE_SESSIONS_KEY, newBounceSessions);
  }

  public static endSessionWithBounce(): number | null {
    const sessionStart = sessionStorage.getItem(SessionManager.SESSION_START_KEY);
    const pageVisitCount = sessionStorage.getItem(SessionManager.PAGE_VISIT_COUNT_KEY);
    if (sessionStart) {
      if (pageVisitCount === '1') {
        SessionManager.incrementBounceSessions();
      }
      const sessionDuration = new Date().getTime() - new Date(sessionStart).getTime();
      sessionStorage.removeItem(SessionManager.SESSION_START_KEY);
      return sessionDuration;
    }
    return null;
  }

  public static getTotalSessions(): number {
    const totalSessions = sessionStorage.getItem(SessionManager.TOTAL_SESSIONS_KEY);
    return totalSessions ? Number(totalSessions) : 0;
  }

  private static incrementNewSessionCount(): void {
    let newSessionCount = sessionStorage.getItem(SessionManager.NEW_SESSION_COUNT_KEY);
    if (!newSessionCount) {
      newSessionCount = '0';
    }
    const updatedCount = String(Number(newSessionCount) + 1);
    sessionStorage.setItem(SessionManager.NEW_SESSION_COUNT_KEY, updatedCount);
  }

  public static getNewSessionCount(): number {
    const newSessionCount = sessionStorage.getItem(SessionManager.NEW_SESSION_COUNT_KEY);
    return newSessionCount ? Number(newSessionCount) : 0;
  }

  public static getNewSessionPercentage(): number {
    const totalSessions = SessionManager.getTotalSessions();
    const newSessionCount = SessionManager.getNewSessionCount();
    if (totalSessions > 0) {
      return (newSessionCount / totalSessions) * 100;
    }
    return 0;
  }

  public static trackPage(pageName: string): void {
    const userFlow = sessionStorage.getItem(SessionManager.USER_FLOW_KEY) || '[]';
    const flowArray = JSON.parse(userFlow) as string[];
    flowArray.push(pageName);
    sessionStorage.setItem(SessionManager.USER_FLOW_KEY, JSON.stringify(flowArray));
  }

  public static getUserFlow(): string[] {
    const userFlow = sessionStorage.getItem(SessionManager.USER_FLOW_KEY) || '[]';
    return JSON.parse(userFlow) as string[];
  }

  public static incrementSearchCount(): void {
    let searchCount = sessionStorage.getItem(SessionManager.SEARCH_COUNT_KEY);
    if (!searchCount) {
      searchCount = '0';
    }
    const newSearchCount = String(Number(searchCount) + 1);
    sessionStorage.setItem(SessionManager.SEARCH_COUNT_KEY, newSearchCount);
  }

  public static getSearchCount(): number {
    const searchCount = sessionStorage.getItem(SessionManager.SEARCH_COUNT_KEY);
    return searchCount ? Number(searchCount) : 0;
  }

  public static isReturningUser(): boolean {
    const returningUser = sessionStorage.getItem(SessionManager.RETURNING_USER_KEY);
    return returningUser === 'true';
  }

  private static setReturningUser(): void {
    sessionStorage.setItem(SessionManager.RETURNING_USER_KEY, 'true');
  }

  public static getVisitFrequency(): number {
    let visitFrequency = sessionStorage.getItem(SessionManager.VISIT_FREQUENCY_KEY);
    if (!visitFrequency) {
      visitFrequency = '0';
    }
    return Number(visitFrequency);
  }

  private static incrementVisitFrequency(): void {
    let visitFrequency = sessionStorage.getItem(SessionManager.VISIT_FREQUENCY_KEY);
    if (!visitFrequency) {
      visitFrequency = '0';
    }
    const newVisitFrequency = String(Number(visitFrequency) + 1);
    sessionStorage.setItem(SessionManager.VISIT_FREQUENCY_KEY, newVisitFrequency);
  }

  public static setButtonId(buttonId: string): void {
    sessionStorage.setItem(SessionManager.BUTTON_ID_KEY, buttonId);
  }

  public static getButtonId(): string {
    let buttonId = sessionStorage.getItem(SessionManager.BUTTON_ID_KEY);
    if (!buttonId) {
      buttonId = '';
    }
    return buttonId;
  }

  public static setUtmSource(utm_source: string): void {
    sessionStorage.setItem(SessionManager.UTM_SOURCE_KEY, utm_source);
  }

  public static getUtmSource(): string {
    let utm_source = sessionStorage.getItem(SessionManager.UTM_SOURCE_KEY);
    if (!utm_source) {
      utm_source = "";
    }
    return utm_source;
  }

  public static setUtmMedium(utm_medium: string): void {
    sessionStorage.setItem(SessionManager.UTM_MEDIUM_KEY, utm_medium);
  }

  public static getUtmMedium(): string {
    let utm_medium = sessionStorage.getItem(SessionManager.UTM_MEDIUM_KEY);
    if (!utm_medium) {
      utm_medium = "";
    }
    return utm_medium;
  }

  public static setUtmCampaign(utm_campaign: string): void {
    sessionStorage.setItem(SessionManager.UTM_CAMPAIGN_KEY, utm_campaign);
  }

  public static getUtmCampaign(): string {
    let utm_campaign = sessionStorage.getItem(SessionManager.UTM_CAMPAIGN_KEY);
    if (!utm_campaign) {
      utm_campaign = "";
    }
    return utm_campaign;
  }

  public static setUtmId(utm_id: string): void {
    sessionStorage.setItem(SessionManager.UTM_ID_KEY, utm_id);
  }

  public static getUtmId(): string {
    let utm_id = sessionStorage.getItem(SessionManager.UTM_ID_KEY);
    if (!utm_id) {
      utm_id = "";
    }
    return utm_id;
  }
}

import { FormField } from '../../../models/form';
import { InputWrapper } from '../input-wrapper/input-wrapper';
import { CFormInput } from '@coreui/react';

import { deserializeDate, serializeDate } from './date-picker-functions';
import { useTranslation } from 'react-i18next';

export type DateProp = {
  enabled: boolean;
  date: string;
};

export const DatePicker = (props: {
  field: FormField;
  maxDate?: DateProp;
  minDate?: DateProp;
  onChange: (val: string) => void;
  value: string;
  warning: any;
}) => {
  const currentValue = props.value ? deserializeDate(props.value) : undefined;
  const { t } = useTranslation();
  return (
    <InputWrapper
      component={
        <CFormInput
          type='date'
          placeholder=' '
          data-e2eid={`date_picker_${props.field.id}`}
          floatingLabel={t(`input-${props.field.id}`)}
          id={props.field.id}
          max={props.maxDate && props.maxDate.enabled ? props.maxDate.date : ''}
          min={props.minDate && props.minDate.enabled ? props.minDate.date : ''}
          onChange={(event) => props.onChange(event.target.value)}
          value={currentValue ? serializeDate(currentValue) : ''}
        />
      }
      warning={props.warning}
      id={props.field.id}
    />
  );
};

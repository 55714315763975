import { CFormSelect } from '@coreui/react';
import { FormField } from '../../../models/form';
import { InputWrapper } from '../input-wrapper/input-wrapper';
import { useTranslation } from 'react-i18next';

export const SelectInput = (props: {
  field: FormField;
  options: { label: string; value: string }[];
  onChange: (val: string) => void;
  value: string;
  warning: boolean;
}) => {
  const { t } = useTranslation();
  return (
    <InputWrapper
      component={
        <CFormSelect
          data-e2eid={`select_input_${props.field.id}`}
          aria-label={t(`input-${props.field.id}`)}
          floatingLabel={t(`input-${props.field.id}`)}
          placeholder=' '
          //@ts-ignore
          options={['', ...props.options]}
          value={props.value}
          onChange={(e: any) => props.onChange(e.target.value)}
        />
      }
      warning={props.warning}
      id={props.field.id}
    />
  );
};

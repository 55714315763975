import React, { useState } from 'react';
import { Box } from 'grommet';
import { useAppSelector, useAppDispatch } from '../../../redux/hooks';
import { StepFormWrapper } from '../../molecules/form-tab-presenter/form-tabStyles';
import { RequestSummary } from '../../atoms/request-summary/request-summary';
import { NextBackButtons } from '../../atoms/nextback/next-back-buttons';
import {
  setTab,
  OrderFlowTabs,
  setLegalConsentOne,
  setNewsConsent,
  OrderFlow,
} from '../../../redux/orderFlow/orderFlowSlice';
import { PrivacyAndTerms } from '../../molecules/legal-links/privacy-and-terms';

import { useNavigate } from 'react-router-dom';
import { CheckBox } from 'grommet';
import { RequiredCheckWrapper, Checkboxes } from '../../atoms/check-box/check-box';
import styled from 'styled-components';
import { processOrderDetailText } from '../../../utils/text-processor';
import { useTranslation } from 'react-i18next';

const PersonalDetailsConfirmation = () => {
  const navigate = useNavigate();
  const dispatch = useAppDispatch();
  const { t } = useTranslation();
  const currentFlow = useAppSelector((state) => state.orderFlow.flow);
  const orderDetails = useAppSelector((state) => state.orderFlow);
  const [privacyCheck, setPrivacyCheck] = useState<boolean>(orderDetails.legalConsentOne);
  const [consentCheck, setConsentCheck] = useState<boolean>(orderDetails.newsConsent);

  const handleContinue = () => {
    if (privacyCheck) {
      if (currentFlow === OrderFlow.Flow1) {
        dispatch(setTab(OrderFlowTabs.HealthCard));
      } else {
        dispatch(setTab(OrderFlowTabs.ExtendedInsurance));
      }
    }
  };
  const handleBack = () => {
    dispatch(setNewsConsent({ newsConsent: false }));
    dispatch(setLegalConsentOne({ legalConsentOne: false }));
    if (currentFlow === OrderFlow.Flow1) {
      dispatch(setTab(OrderFlowTabs.ExtraPersonalDetails));
    } else {
      navigate('/');
    }
  };
  return (
    <Box align='center'>
      <StepFormWrapper $summary>
        <h1>
          {processOrderDetailText(
            t(`personal-data${currentFlow !== OrderFlow.Flow1 ? '-packs' : ''}`),
            orderDetails
          )}
        </h1>
        <RequestSummary orderDetails={orderDetails} />
        <Checkboxes>
          <RequiredCheckWrapper>
            <CheckBoxStyled
              data-e2eid="check_box_pricacy"
              label={''}
              checked={privacyCheck}
              required
              style={{ position: 'relative' }}
              onChange={(event) => {
                const newCheck = event.target.checked;
                setPrivacyCheck(newCheck);
                dispatch(setLegalConsentOne({ legalConsentOne: newCheck }));
              }}
            />
            <PrivacyAndTerms />
          </RequiredCheckWrapper>
          <CheckBoxStyled
            data-e2eid="check_box_consent"
            label={t('check-consent')}
            checked={consentCheck}
            onChange={(event) => {
              const newCheck = event.target.checked;
              setConsentCheck(newCheck);
              dispatch(setNewsConsent({ newsConsent: newCheck }));
            }}
          />
        </Checkboxes>

        <NextBackButtons
          next={{ f: handleContinue, label: 'NEXT', disabled: !privacyCheck }}
          back={{ f: handleBack, label: 'BACK' }}
        />
      </StepFormWrapper>
    </Box>
  );
};

export default PersonalDetailsConfirmation;

const CheckBoxStyled = styled(CheckBox)`
  box-shadow: unset;
`;

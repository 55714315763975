import { CFormInput } from '@coreui/react';
import '@coreui/coreui/dist/css/coreui.min.css';
import { InputWrapper } from '../input-wrapper/input-wrapper';
import { FormField } from '../../../models/form';
import { useTranslation } from 'react-i18next';

export const TextInput = (props: {
  field: FormField;
  value: string;
  onChange: (val: string) => void;
  warning: boolean;
  maxLength?: number;
}) => {
  const { t } = useTranslation();
  return (
    <InputWrapper
      component={
        <CFormInput
          data-e2eid={`text_input_${props.field.id}`}
          placeholder=' '
          floatingLabel={t(`input-${props.field.id}`)}
          value={props.value}
          onChange={(e: any) => props.onChange(e.target.value)}
          maxLength={props.maxLength}
        />
      }
      warning={props.warning}
      id={props.field.id}
    />
  );
};

import { useEffect, useState } from 'react';
import { sendLog } from '../../analytics/analytics';
import { postOrderDetails, resetOrderDetails } from '../../redux/api/OrderApi';
import { getUserDetails } from '../../redux/api/SecondFlowApi';
import { useAppDispatch, useAppSelector } from '../../redux/hooks';
import {
  OrderFlow,
  OrderFlowTabs,
  setOrderDetails,
  setTab
} from '../../redux/orderFlow/orderFlowSlice';
import { SessionManager } from '../../utils/session-manager';
import ExtendedInsurance from './tabs/ExtendedInsurance';
import ExtensionFlow from './tabs/ExtensionFlow';
import ExtraPersonalDetails from './tabs/ExtraPersonalDetails';
import HealthCard from './tabs/HealthCard';
import IdentificationDetails from './tabs/IdentificationDetails';
import LoadingTab from './tabs/LoadingTab';
import OrderSummary from './tabs/OrderSummary';
import Payment from './tabs/Payment';
import PersonalDetails from './tabs/PersonalDetails';
import PersonalDetailsConfirmation from './tabs/PersonalDetailsConfirmation';
import TravelDetails from './tabs/TravelDetails';
import ErrorTab from './tabs/ErrorTab';

const FormController = () => {
  const dispatch = useAppDispatch();
  const orderDetails = useAppSelector(state => state.orderFlow);
  const { currentTab, flow, ...orderDetailsWithoutHandlers } = orderDetails;
  const orderApiCall = useAppSelector(state => state.orderApi.orderDetails);
  const checkIdRepetitionApiCall = useAppSelector(
    state => state.orderApi.checkIdRepetition
  );
  const userApiCall = useAppSelector(state => state.secondFlowApi.userApi);
  const urlParams = new URLSearchParams(window.location.search);
  const [errorMessage, setErrorMessage] = useState('');
  const extendedInsurance = urlParams.get('extendedInsurance');
  const token = urlParams.get('token');
  const query = urlParams.toString();
  const userId = SessionManager.getUserId();
  const orderDetailsWithUserSession = {
    ...orderDetailsWithoutHandlers,
    userSession: userId
  };
  useEffect(() => {
    if (currentTab === OrderFlowTabs.PersonalDetails) {
      SessionManager.startSession();
    }
    const startTime = new Date().getTime();
    SessionManager.trackPage(window.location.href);
    const userFlow = SessionManager.getUserFlow();
    SessionManager.incrementPageVisitCount();
    const sessionDuration = SessionManager.endSession();
    const endTime = new Date().getTime();
    const pageLoadTime = endTime - startTime;
    const searchCount = SessionManager.getSearchCount();
    const userAgent = navigator.userAgent;
    sendLog(OrderFlowTabs[currentTab], {
      userId,
      sessionDuration,
      pageVisitsCount: SessionManager.getPageVisitCount(),
      isMobile: SessionManager.isMobile(),
      isComputer: SessionManager.isComputer(),
      newSessionPercentage: SessionManager.getNewSessionPercentage(),
      userFlow,
      userOrigin: SessionManager.getUtmSource(),
      pageLoadTime,
      searchCount,
      isReturningUser: SessionManager.isReturningUser(),
      visitFrequency: SessionManager.getVisitFrequency(),
      query,
      userAgent,
      buttonId: SessionManager.getButtonId(),
      utm_source: SessionManager.getUtmSource(),
      utm_medium: SessionManager.getUtmMedium(),
      utm_campaign: SessionManager.getUtmCampaign(),
      utm_id: SessionManager.getUtmId()
    });
  }, [currentTab, query]);

  useEffect(() => {
    if (token?.length) {
      const tokenString = token.toString();
      dispatch(getUserDetails(tokenString));
    }
  }, [token]);

  useEffect(() => {
    if (userApiCall.loading === 'pending') {
      dispatch(setTab(OrderFlowTabs.Loading));
    } else if (
      userApiCall.loading === 'succeeded' &&
      userApiCall.response.httpCode === 200
    ) {
      dispatch(setOrderDetails(userApiCall.response.userDetails as OrderFlow));
      dispatch(setTab(OrderFlowTabs.PersonalDetailsConfirmation));
    } else if (
      userApiCall.loading === 'succeeded' &&
      userApiCall.response.httpCode === 401
    ) {
      setErrorMessage('token-expired-error');
      dispatch(setTab(OrderFlowTabs.Error));
    }
  }, [userApiCall.loading, userApiCall.response]);

  useEffect(() => {
    if (extendedInsurance === 'true') {
      dispatch(setTab(OrderFlowTabs.ExtensionFlow));
    }
  }, [extendedInsurance]);

  useEffect(() => {
    if (
      checkIdRepetitionApiCall.loading === 'succeeded' &&
      checkIdRepetitionApiCall.response.httpCode === 200 &&
      checkIdRepetitionApiCall.response.ch === true
    ) {
      dispatch(postOrderDetails(orderDetailsWithUserSession));
    } else if (
      checkIdRepetitionApiCall.loading === 'succeeded' &&
      !checkIdRepetitionApiCall.response.ch
    ) {
      setErrorMessage('duplicated-user-error');
      dispatch(setTab(OrderFlowTabs.Error));
    }
  }, [checkIdRepetitionApiCall.loading, checkIdRepetitionApiCall.response]);

  useEffect(() => {
    if (
      orderApiCall.loading === 'succeeded' &&
      orderApiCall.response.httpCode === 200
    ) {
      dispatch(resetOrderDetails());
      dispatch(setTab(OrderFlowTabs.Payment));
    } else if (orderApiCall.loading === 'pending') {
      dispatch(setTab(OrderFlowTabs.Loading));
    }
  }, [orderApiCall.loading, orderApiCall.response]);

  const tabComponents = {
    [OrderFlowTabs.PersonalDetails]: <PersonalDetails />,
    [OrderFlowTabs.TravelDetails]: <TravelDetails />,
    [OrderFlowTabs.IdentificationDetails]: <IdentificationDetails />,
    [OrderFlowTabs.ExtraPersonalDetails]: <ExtraPersonalDetails />,
    [OrderFlowTabs.PersonalDetailsConfirmation]: (
      <PersonalDetailsConfirmation />
    ),
    [OrderFlowTabs.HealthCard]: <HealthCard />,
    [OrderFlowTabs.ExtendedInsurance]: <ExtendedInsurance />,
    [OrderFlowTabs.OrderSummary]: <OrderSummary />,
    [OrderFlowTabs.ExtensionFlow]: <ExtensionFlow />,
    [OrderFlowTabs.Payment]: <Payment />,
    [OrderFlowTabs.Loading]: <LoadingTab />,
    [OrderFlowTabs.Error]: <ErrorTab error={errorMessage} />
  };

  return tabComponents[currentTab] || <div>Default</div>;
};

export default FormController;
